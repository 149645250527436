$(function() {
        $(window).scroll(function() {
            var $scroll = $(this).scrollTop()
            var $app_video1 = $(".app-video1").eq(0).offset().top
            var $app_video2 = $(".app-video2").eq(0).offset().top
            var $app_video3 = $(".app-video3").eq(0).offset().top
            var $app_video4 = $(".app-video4").eq(0).offset().top
            var $app_video5 = $(".app-video5").eq(0).offset().top
            var $app_video6 = $(".app-video6").eq(0).offset().top
            var $video1 = $('.app-video1 video')
            var $video2 = $(".app-video2 video")
            var $video3 = $(".app-video3 video")
            var $video4 = $(".app-video4 video")
            var $video5 = $(".app-video5 video")
            var $video6 = $(".app-video6 video")
            if ($scroll >= $app_video1 - 450) {
                $video1[0].play();
            } else {
                $video1[0].pause();
                $video1[0].currentTime = 0;
            }
            if ($scroll >= $app_video2 - 450) {
                $video2[0].play();
            } else {
                $video2[0].pause();
                $video2[0].currentTime = 0;
            }
            if ($scroll >= $app_video3 - 450) {
                $video3[0].play();
            } else {
                $video3[0].pause();
                $video3[0].currentTime = 0;
            }
            if ($scroll >= $app_video4 - 450) {
                $video4[0].play();
            } else {
                $video4[0].pause();
                $video4[0].currentTime = 0;
            }
            if ($scroll >= $app_video5 - 450) {
                $video5[0].play();
            } else {
                $video5[0].pause();
                $video5[0].currentTime = 0;
            }
            if ($scroll >= $app_video5) {
                $video6[0].play();
            } else {
                $video6[0].pause();
                $video6[0].currentTime = 0;
            }
        })

    })